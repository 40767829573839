@use "@/styles/abstracts" as *;

.product-footer {
	padding-block: var(--product-padding-block);
	padding-inline: var(--product-padding-inline);

	@include body-2;

	display: grid;
	gap: rem(24);
	grid-template-areas:
		"rates"
		"info"
		"cta"
		"disclaimer";

	@include breakpoint($md) {
		grid-template-areas:
			"rates info cta"
			"accordion accordion accordion"
			"disclaimer disclaimer disclaimer";
		grid-template-columns: auto 1fr auto;
		gap: rem(8) rem(40);
	}

	@include breakpoint($lg) {
		padding-top: 0;
	}

	&::before {
		content: attr(data-rates-button);
		grid-area: rates;
		opacity: 0;
		appearance: none;
		visibility: none;
		pointer-events: none;
		user-select: none;
		position: relative;
		z-index: -1;
	}

	&__rates {
		grid-area: rates;
		@include breakpoint($md) {
			grid-area: rates / rates / accordion / accordion;
		}

		&--expanded &-header svg {
			transform: rotate(-180deg);
		}

		& &-header {
			text-align: left;
			width: auto;
			display: flex;
			align-items: center;
			font-size: inherit;
			text-decoration: underline;
			cursor: pointer;
			gap: 0.2em;

			svg {
				width: 0.6em;
				height: 1em;
				transition: transform $timing-short $ease-std;
			}
		}
	}

	@include breakpoint($md) {
		&__rates-header,
		&__info {
			min-height: rem(52);
		}
	}

	&__info {
		grid-area: info;
		display: flex;
		align-items: center;
		color: inherit;
		justify-self: flex-start;
	}

	&__cta {
		display: grid;
		grid-area: cta;
	}

	&__disclaimer {
		grid-area: disclaimer;
		@include body-3;

		color: $color-grey--800;
	}
}

.product-rates {
	display: grid;
	grid-auto-columns: 1fr;
	padding: rem(24) 0;
	margin: 0;
	row-gap: rem(24);

	@include breakpoint($md) {
		padding: rem(32) 0;
		margin: 0 rem(-24);
		grid-auto-flow: column;
	}

	@include breakpoint($lg) {
		padding: rem(40) 0;
	}

	div {
		display: grid;
		text-align: center;
		align-content: flex-start;
		justify-content: center;
		gap: rem(8);
		position: relative;
		padding: 0;

		@include breakpoint($md) {
			padding: 0 rem(24);
			// margin: rem(40) 0;
			+ div {
				&::before {
					content: "";
					border-left: 1px solid $color-orange--200;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
				}
			}
		}
	}

	dt {
		font-weight: $fw-bold;
		margin: 0;
		padding: 0;
	}

	dd {
		margin: 0;
		padding: 0;
		display: grid;
		align-items: flex-start;
		gap: rem(8);
		p {
			margin: 0;
		}
	}
}
