@use "@/styles/abstracts" as *;

.product-card {
	@include theme-dawn;
	background-color: var(--theme--background);
	color: var(--theme--text-color);
	border-radius: 20px;
	overflow: hidden;

	--product-padding-block: #{rem(24)};
	--product-padding-inline: #{rem(24)};

	@include breakpoint($md) {
		--product-padding-inline: #{rem(32)};
	}

	@include breakpoint($lg) {
		--product-padding-block: #{rem(32)};
		--product-padding-inline: #{rem(40)};
	}

	&__separator {
		margin: 0 var(--product-padding-inline) 0;
		border: none;
		border-bottom: 1px solid $color-orange--200;

		&--bottom {
			@include breakpoint($lg) {
				display: none;
			}
		}
	}
}
