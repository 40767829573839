@use "@/styles/abstracts" as *;

.faq-accordion-wrapper {
	background: var(--theme--card-bg, #{$color-white});
	border-radius: 24px;
	overflow: hidden;
	box-shadow: 2px 4px 10px 0px rgba(32, 1, 88, 0.03);
}

.faq-accordion {
	@include theme-dawn;
	color: var(--theme--text-color);

	--padding-y: #{rem(16)};
	--padding-x: #{rem(24)};

	@include breakpoint($md) {
		--padding-y: #{rem(24)};
		--padding-x: #{rem(32)};
	}

	[aria-expanded] {
		outline-color: $color-orange--primary;
		outline-offset: -3px;
		border-radius: 24px;

		@include hover-focus-visible {
			color: $color-orange--primary;
		}
	}

	&__header {
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: var(--padding-y) var(--padding-x);
		cursor: pointer;
		@include body-2;
		transition: color $timing-shortest $ease-std;

		[aria-expanded="true"] > & {
			svg {
				transform: rotate(180deg);
			}
		}

		svg {
			color: $color-orange--primary;
			width: rem(15);
			height: auto;
			transition: transform $timing;
		}
	}

	&__heading {
		font-weight: $fw-bold;
		font-size: rem(18);
	}

	&__body {
		padding: 0 var(--padding-x) var(--padding-y);
		@include body-2;
	}
}
