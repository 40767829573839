@use "@/styles/abstracts" as *;

.accordion {
	&__header {
		margin: 0;
	}

	&__button {
		display: block;
		width: 100%;
		font-weight: normal;
		color: currentColor;
		margin: 0;
		padding: 0;
		border: none;
		background: none;
		appearance: none;
	}

	&__body {
		overflow: hidden;
	}
}
