@use "@/styles/abstracts" as *;

.faq-block {
	padding: rem(60) 0;

	@include breakpoint($md) {
		padding: rem(80) 0;
	}

	@include breakpoint($lg) {
		padding: rem(96) 0;
	}

	&--dawn {
		@include theme-dawn;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--dusk {
		@include theme-dusk;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--midnight {
		@include theme-midnight;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}

	&__wrapper {
		@include container($container-std);
	}

	&__inner {
		display: grid;
		gap: rem(40);

		@include breakpoint($md) {
			gap: rem(48);
		}
		@include breakpoint($lg) {
			gap: rem(56);
		}
	}

	&__content {
		text-align: center;
	}

	&__header {
		display: grid;

		justify-items: center;
		position: relative;
		@include breakpoint($md) {
			display: flex;
			align-items: baseline;
			justify-content: center;
		}
	}

	&__heading {
		@include h3;
		margin: 0;
	}

	&__flourish {
		order: -1;
		margin-bottom: rem(16);
		width: rem(75);
		height: rem(75);
		@include breakpoint($md) {
			order: 0;
			margin: 0;
			width: rem(90);
			height: rem(90);

			margin-top: rem(-90);
			margin-right: rem(-90);
		}

		@include breakpoint($lg) {
			width: rem(110);
			height: rem(110);
			margin-top: rem(-110);
			margin-right: rem(-110);
		}
	}

	&__body {
		@include body-2;
	}

	&__filter {
		display: flex;
		flex-direction: column;
		gap: rem(12);

		@include breakpoint($sm) {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			gap: rem(18) rem(40);
		}

		@include breakpoint($md) {
			gap: rem(20) rem(48);
		}
	}

	& &__radio-input:checked + &__radio-label {
		text-decoration: underline;
	}
	& &__radio-label {
		font-weight: $fw-bold;
	}

	&__list {
		display: grid;
		list-style: none;
		margin: 0;
		padding: 0;
		gap: rem(16);

		@include breakpoint($md) {
			gap: rem(24);
		}
		@include breakpoint($lg) {
			gap: rem(32);
		}
	}
}
