@use "@/styles/abstracts" as *;

.pipeline-map {
	display: grid;
	grid-template-rows: auto 1fr;
	height: 85vh;
	gap: rem(32);

	@include breakpoint($sm) {
		gap: rem(24);
	}
	@include breakpoint($md) {
		gap: rem(32);
	}

	&__header {
		display: grid;
		grid-template-areas:
			"input"
			"button";
		gap: rem(12);
		@include breakpoint($sm) {
			gap: rem(16);
			grid-template-areas: "input button";
			grid-template-columns: 1fr auto;
		}
		@include breakpoint($md) {
			gap: rem(24);
		}
	}

	&__input-wrapper {
		grid-area: input;
		--input-icon: #{rem(32)};
		display: grid;
		grid-template-columns: var(--input-icon) 1fr;
	}

	&__input {
		@include input-style;
		--input-left: var(--input-icon);
		grid-column: 1/-1;
		grid-row: 1;
	}

	&__icon {
		grid-column: 1;
		grid-row: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		pointer-events: none;
		position: relative;
		z-index: 1;
		color: $color-text;
		margin-right: rem(-10);
	}

	&__button {
		grid-area: button;
	}

	&__map {
		@include theme-dawn;
		color: var(--theme--text-color);
		width: 100%;
		border-radius: 10px;
		overflow: hidden;

		[tabindex]:focus + :global(.gm-style) {
			iframe + div {
				opacity: 0 !important;
			}
		}

		:global {
			.gm-style-iw-c {
				@include body-2;
				border-radius: 24px;
				border-bottom-right-radius: 64px;
				box-shadow: 2px 4px 10px 0px #2001581a;
				max-width: rem(327) !important;
				margin-bottom: rem(6) !important;

				padding: rem(12) rem(12) rem(32) !important;
				@include breakpoint($md) {
					max-width: rem(440) !important;
					padding: rem(16) rem(16) rem(40) !important;
				}
			}
			.gm-style-iw-chr {
				display: none;
				width: 100%;
			}
			.gm-style-iw-d {
				padding: 0 !important;
				overflow-y: auto !important;
				overflow-x: hidden !important;
			}
		}
	}

	&__loading {
		align-self: center;
		justify-self: center;
		grid-row: 1/-1;
	}
}

.info-window {
	display: grid;

	&__close {
		width: 1em;
		height: 1em;
		font-size: rem(16);
		padding: 0;
		margin: 0;
		appearance: none;
		color: inherit;
		background: none;
		border: none;
		justify-self: flex-end;
		cursor: pointer;
		@include breakpoint($sm) {
			font-size: rem(18);
		}
		@include breakpoint($md) {
			font-size: rem(24);
		}
		span {
			font-size: 1em;
		}
	}

	&__content {
		display: grid;
		gap: rem(16);
		padding: 0 rem(16);
		@include body-3;
		font-weight: $fw-medium;
		strong {
			font-weight: $fw-black;
		}
		a {
			color: $color-purple--primary;
		}
	}

	&__header {
		display: flex;
		gap: 0.5em;
	}

	&__icon {
		font-size: 1.8em;
		&--success {
			color: $color-purple--primary;
		}
		&--warn {
			color: $color-orange--primary;
		}
	}

	&__heading {
		font-weight: $fw-black;
		@include body-1;
	}
}

.pipeline-error {
	padding-block: 2em;
	text-align: center;

	&__heading {
		@include body-1;
		margin: 0;
	}

	&__message {
		@include body-2;
		margin-top: 0.5em;
	}
}
